.fifth-heghlight-wrapper .left-side-wrapper .left-side-container{
	background-image:  url("../../../images/home/video.png"); 
	background-color: #000;
	padding-top: 35px;
	margin: 0 0 10px 0;
	background-size: cover;		
}
.fifth-heghlight-wrapper .right-side-wrapper .right-side-container{
	background-image:  url("../../../images/home/concept.png"); 
	background-color: #fbfbfd;
	background-position: bottom;
	padding-top: 35px;
	margin: 0 0 10px 0;
	background-size: auto;	
}
.fifth-heghlight-wrapper .description-wraper {
	max-width: 450px;
	margin: 7px auto;
	color: #ffffff;
}

@media (min-width: 768px) {
.fifth-heghlight-wrapper .left-side-wrapper .left-side-container {
	background-image: url("../../../images/home/video.png");
    margin: 5px 5px 5px 10px;
}
.fifth-heghlight-wrapper .right-side-wrapper .right-side-container {
    margin: 5px 10px 5px 5px;
}
}

.title-wraper.hub {
    font-weight: 600;
    font-size: 30px;
    line-height: 1.15em;
    letter-spacing: -0.04em;
    color: #ffffff;
}

.price-wrapper.hub {
    font-size: 17px;
    color: #ffffff;
}