.map-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #fff;
  padding-top: 80px; 
  padding-bottom: 0;

}


.romania-map {
  width: 100%;
  height: auto;
}



.tooltip {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 10; /* Asigură-te că este deasupra hărții */
  transform: translateY(-20px); /* Se va ridica puțin la început */
  opacity: 0; /* Inițial invizibil */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* Animație pentru tooltip */
}

.tooltip-enter {
  opacity: 1; 
  transform: translateY(0); 
}



.county {
  fill: #ccc; 
  stroke: #333; 
  cursor: pointer;
  transition: fill 0.3s;
}

.county:hover {
  fill: #1ab59e; 
}

#RO-RO {
  pointer-events: none; 
  fill: #666; 
}