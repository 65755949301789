/* General Styling*/
*::before,
*::after{
    margin: 0;
    padding: 0;
}
body {
	font-family: 'SF Pro Display',sans-serif;;
}



a {
	color: #1ab59e; 
} 
img {
	max-width: 100%;
}
.white {
	color: #fff;
}
.black {
	color: #1d1d1f;
}
.grey {
	color: #86868b;
}
.top-50 {
	margin-top: 50px;
}

a:hover {
	color: red;
}


.upper-text-container {
	border-bottom: 1px solid #d2d2d7;
	padding: 17px 0 10px;
	margin-bottom: 20px;
}
.upper-text-container a {
	color: #515154;
}


.left-side-container {
	min-height: 515px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;	
}

.right-side-container {
	min-height: 515px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;	
}
.left-side-wrapper {
  padding-right: 0px;
  padding-left: 0px;
}

.right-side-wrapper {
  padding-right: 0px;
  padding-left: 0px;
}
.sixth-heghlight-wrapper .links-wrapper {
	max-width: 300px;
	margin: 0 auto;
}
.links-wrapper ul {
	list-style: none;
}
.product-title {
	font-weight: 400;
	font-size: 37px;
}
.brief-description {
	font-size: 3em;
}
.sixth-heghlight-wrapper .description-wraper {
	max-width: 250px;
	margin: 7px auto;
}
.description-wraper.white{
	color: white;

}
.description-wraper{
	font-size: 3em;
}
.links-wrapper {
	padding: 10px 0;
}
.links-wrapper ul li {
	display: inline-block;
	padding-right: 20px;
}
.links-wrapper ul li a {
	color: #1ab59e;
	text-decoration: none;
	font-size: 17px;
}
.links-wrapper ul li a:hover {
	text-decoration: underline;
	
}
.links-wrapper ul li a:after {
  font-family: "FontAwesome";
  font-weight: 400;
  content: "\f105";
  padding-left: 10px;
}
.title-wraper {
  font-weight: 600;
  font-size: 30px;
  line-height: 1.15em;
  letter-spacing: -0.04em;
}
.title-wraper.bold {
  font-weight: 600;
  font-size: 27px;
  line-height: 1.12em;
  letter-spacing: -0.04em;
}
.right-side-wrapper .title-wraper {
	max-width: 280px;
	margin: 0 auto;
} 
.description-wrapper {
	font-size: 26px;
	padding: 10px 0;
}
.price-wrapper{
	font-size: 17px;
}

.watch-more-wrapper a {
	text-decoration: none;
	font-size: 0.85em;
	color: #fff;
}
.watch-more-wrapper a:after {
	font-family: "FontAwesome";
	font-weight: 900;
	content: "\f144";
    padding-left: 15px;
}
.tvshow-logo-wraper {
	padding: 10px 0 0px;
}

.links-wrapper.white li a {
	color: #fff;
}
.fourth-heghlight-wrapper .right-side-wrapper .title-wraper {
    max-width: 380px;
}

/* Alert section */
.alert-section {
	text-align: center;
	padding: 20px;
}
.alert-title {
	font-weight: 600;
	color: #1d1d1f;
	padding: 10px;
}
.alert-text {
	max-width: 90%;
	margin: 0 auto;
}
/* .navbar-toggleable-sm{
	height: 60px;
} */

@media (min-width: 768px) {
	/*Nav bar*/
	/* .nav-item {
		text-align: center !important;
		padding: 10px;
		border-bottom: 0px;
	}	 */
	/*Footer styling*/
	.my-apple-wrapper {
		border-bottom: 1px solid #d2d2d7
	}

	.title-wraper.bold {
	  font-size: 56px;
	}
	/* Second section */
	
	.new-alert {
		color: #BF4801;
		font-size: 18px;
	}

	/*Alert section*/
	.alert-text {
		max-width: 85%;
		margin: 0 auto;
	}	

}
@media (min-width: 992px) {
	/*Footer styling*/
	.copyright {
		margin-top: 10px;
	}
	.footer-country {
		margin-top: 10px;
	}
	.footer-links-terms {
		margin-top: 10px;
	}	
}

.internal-page-wrapper {
	margin-top: 44px;
	padding: 20px 0;	
}

/* Product page */
.product-title {
	font-size: 30px;
	font-weight: 600;	
  }
  .product-brief {
	font-size: 30px;
	font-weight: 700;
	line-height: 1.2em;	
  }
  .starting-price {
	  font-size: 25px;
	  font-weight: 600;
  }
  .product-holder {
	  margin: 100px 0;
  }
  .product-details {
	  padding: 50px 0;
	  font-size: 18px;
  }
 .large{
	font-size: 56px;
  }
  .inch{
	  font-size: 17px;
  }

  .monthly-price{
	font-size: 15px;
  }

  .locatii-section {
    background-image: url('../images/home/locatii.png');
	background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    padding: 250px;
}

  .despre-section {
    background-image: url('../images/home/despre-noi.png');
	background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    padding: 250px;
}

.product-title.blog {
    font-size: 25px;
    font-weight: 600;
}

.description-wraper.blog {
    font-size: 1.5em;
}

.zona-description{
	font-size: 1.5em;
    text-align: left;
    padding: 15px;
}

.zona-clienti {
    text-align: left;
    font-size: 1.5em;
	margin-left: 50px;
}

.profesionisti-section {
    background-image: url('../images/home/zona-profesionisti.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    padding: 250px;
}

.clienti-section {
    background-image: url('../images/home/zona-clienti.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    padding: 250px;
}

.blog-section {
    background-image: url('../images/home/blog.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    padding: 250px;
}

.programare-section {
    background-image: url('../images/home/programari-2.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    padding: 250px;
}

.despre{
	font-size: 45px;
}

.oracle-section {
    background-image: url('../images/home/oracle.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    padding: 250px;
}

.zona-profesionisti {
    text-align: left;
    font-size: 1.5em;
	margin-left: 50px;
	list-style-type: none;
}

.ploiesti-section {
    background-image: url('../images/home/philips-colaboratori.jpg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    padding: 70px;
}

.logo-ploiesti-section {
    background-image: url('../images/home/logo-ploiesti.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    padding: 70px;
	margin: 20px;
}

.blog-title{
	font-size: 3rem;
    font-weight: 600;
    padding: 20px;
}

.blog-brief{
	font-size: 1.7rem;
    text-align: left;
}

.blog-description{
	font-size: 1.7rem;
    text-align: left;
	padding-top: 10px;
}

.blog-holder{
	margin: 100px 0;
}

.button-ajutor{
    background-color: #fff !important;
    color: #1ab59e !important;
    font-size: 17px !important;
	border: 0px !important;
	font-weight: normal !important;
}

.button-ajutor:hover {
    text-decoration: underline;
}

.locatie-telefon{
	font-size: 23px;
}

.programare{
    font-size: 23px;
    list-style-type: none;
}

@media (max-width: 768px) {
	
	.row.locatie{
		display: contents !important;
	}

	.row.ajutor{
		display: contents !important;
	}

	.row.product{
		display: contents !important;
	}

	.row.blog{
		display: contents !important;
	}

	.oracle-section {
		padding: 57px;
	}

	.row.colaborator{
		display: contents !important;
	}

	iframe {
        width: 100%; 
        height: 300px;
    }

	.large {
		font-size: 30px;
		font-weight: 600;
	}

	.despre-section {
		padding: 57px;
	}

	.profesionisti-section {
		padding: 57px;
	}

	.clienti-section {
		padding: 57px;
	}

	.locatii-section {
		padding: 57px;
	}

	.blog-section {
		padding: 57px;
	}

	.programare-section {
		padding: 57px;
	}

	.product-title {
		font-size: 25px;
		font-weight: 600;
		padding-top: 20px;
	}


	.second-hightlight-wrapper {
		height: 600px;
	}

	.first-hightlight-wrapper{
		height: 600px;
	}

	.blog-title {
		font-size: 20px;
		font-weight: 600;
		padding: 20px;
	}

	.description-wraper.blog {
		padding: 15px;
	}

	.description-wraper {
		font-size: 2.2em;
	}

	.product-holder {
		margin: 20px 0;
	}

	.product-details {
		padding: 15px 0;
	}

	.button-ajutor{
		display: none;
	}
}

