.footer-wrapper {
    background-color: #f5f5f7;
    color: #86868b;
    font-size: 12px;
  }
  .footer-wrapper ul {
      list-style: none;
      padding-bottom: 10px;
      padding-inline-start: 0px;
  }
  .footer-wrapper ul a {
      color: #515154;
  }
  .my-apple-wrapper {
      padding-bottom: 15px;
     margin-top: 10px;
  }
  .copyright {
      margin-top: 15px;
  }
  .footer-country {
      margin-top: 8px;
  }
  .flag-wrapper {
      display: inline-block;
      vertical-align: middle;
      padding-right: 5px;
  }
  .footer-country-name{
      display: inline-block;
      vertical-align: middle;
  }
  .footer-links-terms ul {
      display: flex;
  }
  .footer-links-terms ul li {
      padding: 0 10px;
      border-right: 1px solid #d2d2d7;
  }
  .footer-links-terms ul li:first-child {
      padding-left: 0;
  }
  .footer-links-terms ul li:last-child {
      border-right: 0;
  }
  
  .footer-links-wrapper h3 {
      font-size: 12px;
      font-weight: 600;
      color: #1d1d1f;
  }
  .footer-wrapper ul li{
      padding: 4px 0;
  }

  /*Media Query*/
/* Footer */
@media (max-width: 768px){
	.footer-links-wrapper ul {
		display: none;
	}
	.footer-links-wrapper h3 {
		padding: 10px 0;
		border-bottom: 1px solid #ccc;
	}
	.footer-links-wrapper h3:after {
		font-family: "FontAwesome";
		content: "\f067";
		padding-left: 10px;
		position: absolute;
		right: 25px;
	}
}